import React from "react"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import Headings from "../components/headings"
import Cta from "../components/call_to_action"
import { Link } from "gatsby" 

// Add Page Head component and pass in SEO variables from GraphQL
export const Head = ({ data }) => {
  const title = "Analytics and Data - Sorry we made a mistake"
  const description = "Our customer data platform (CDP) knowledge is unrivalled, but we can't find the page you are looking for. Please try one of our other pages instead." 
  return (
    <SEO title={title} description={description} />
  )
}

// Return error page layout 
export default function ErrorPage ({ data }) { 
    return (
        <Layout>  
         <div className="container"> <Headings
            title="404 page, Sorry this is not the droid you are looking for"
            description="Maybe we made a mistake, or maybe you did. Either way, we are sorry."
          /></div>
          <div className="container pt-6 text-gray-600">
          Please try one of our other pages instead.
          <ul className="list-inside list-disc pt-4">
              <li><Link to="/" className="text-black hover:text-red-400">Home page</Link></li>
              <li><Link to="/service" className="text-black hover:text-red-400">Strategy</Link></li>
              <li><Link to="/products" className="text-black hover:text-red-400">Books and free products</Link></li>
              <li><Link to="/services/fractional-analytics" className="text-black hover:text-red-400">Fractional Analytics work</Link></li>
              <li><Link to="/blog" className="text-black hover:text-red-400">Or head over to our blog</Link></li>
          </ul>
          </div>
          <div className="container pt-6 pb-6 text-gray-600">
            Failing that you might want to download our free whitepaper. Please us the form below.
          </div>
          <Cta />
        </Layout>
    )
}


import React from "react"

const Headings = ({ title, description }) => {
  return (
    <header>
      <h1 className="pt-8 text-5xl font-bold text-red-400">{title}</h1>
      {description && (
        <p className="mt-4 text-2xl text-black">{description}</p>
      )}
    </header>
  )
}

export default Headings
